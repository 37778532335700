<template>
  <div>
    <badgeSuccess
      :name="module.name"
      v-for="module in modules"
      :key="module"
      class="rounded-pill w-auto"
      style="cursor: pointer"
      @click="showPlanDetail(module)"
    />
  </div>
</template>
<script>
import badgeSuccess from "../../components/shared/badgeSuccess.vue";

export default {
  components: {
    badgeSuccess,
  },
  props: ["month_key", "week_key", "plans"],
  data() {
    return {};
  },
  computed: {
    modules() {
      return this.plans.filter(
        (plan) => plan.month === this.month_key && plan.week === this.week_key
      );
    },
  },
  methods: {
    showPlanDetail(data) {
      this.$emit("showModalData", data);
    },
  },
};
</script>